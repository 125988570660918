import { Auth, API } from "aws-amplify";
import moment from 'moment-timezone';

export async function bookZoomMeeting(zoomUserId, topic, duration, meetingDate, participants) {

	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const timezone = moment.tz.guess();

	const apiName = 'ZoomMeetingsAPI';
	const path = '';
	const myInit = {
		headers: {
			Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
		},
		body: {
			userId: accountId,
			zoomUserId: zoomUserId,
			topic: topic,
			duration: duration,
			startTime: meetingDate,
			timezone: timezone,
			participants: participants
		},

	};

	return await API.post(apiName, path, myInit)

};