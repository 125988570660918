import { getZoomMeetingReport } from "../../api/ZoomMeetingReport";
import {
	fetchZoomMeetingRequest,
	fetchZoomMeetingFailure,
	fetchZoomMeetingSuccess,
} from "./zoomMeetingReportAction";

export const fetchZoomMeetingReport = (zoomMeetingId) => {
	return (dispatch) => {
		//Initializing the request
		dispatch(fetchZoomMeetingRequest());
		//Calling the api
		getZoomMeetingReport(zoomMeetingId)
			.then((response) => {
				// response is the zoom meeting report
				dispatch(fetchZoomMeetingSuccess(response));
			})
			.catch((error) => {
				// error.message is the error message
				dispatch(fetchZoomMeetingFailure(error.message));
			});
	};
};
