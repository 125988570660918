export const fetchZoomMeetingsRequest = () => {
	return {
		type: "FETCH_ZOOM_MEETINGS_REQUEST",
	};
};

export const fetchZoomMeetingsSuccess = (zoomMeetings) => {
	return {
		type: "FETCH_ZOOM_MEETINGS_SUCCESS",
		payload: zoomMeetings,
	};
};

export const fetchZoomMeetingsFailure = (error) => {
	return {
		type: "FETCH_ZOOM_MEETINGS_FAILURE",
		payload: error,
	};
};
