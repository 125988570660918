import React from "react";

import SummaryCard from "./SummaryCard";
import { calculateSummary } from "./helper";

import dashboard_neutral from "../../assets/dashboard_neutral.png";
import dashboard_happy from "../../assets/dashboard_happy.png";
import dashboard_disgust from "../../assets/dashboard_disgust.png";
import dashboard_angry from "../../assets/dashboard_angry.png";
import dashboard_sad from "../../assets/dashboard_sad.png";
import dashboard_fear from "../../assets/dashboard_fear.png";
import Loading from "../../assets/loading.gif";

const SummaryCardSection = ({ faceEmotions, userId }) => {

	const { timeSummary, happySummary, negativeSummary } = calculateSummary(
		faceEmotions,
		userId
	);

	return (
		<section className='summary summary-text-gray'>
			<div className='container'>
				<div className='row'>
					<div className='col-md-4'>
						<SummaryCard
							icon={dashboard_neutral}
							type='Face Detection Time'
							summary={timeSummary}
							loading={faceEmotions.loading}
						/>
					</div>
					<div className='col-md-4'>
						<SummaryCard
							icon={dashboard_happy}
							type='Happy'
							summary={happySummary}
							loading={faceEmotions.loading}
						/>
					</div>
					<div className='col-md-4'>
						<SummaryCard
							disgustIcon={dashboard_disgust}
							angryIcon={dashboard_angry}
							sadIcon={dashboard_sad}
							fearIcon={dashboard_fear}
							type='Negative'
							summary={negativeSummary}
							loading={faceEmotions.loading}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default SummaryCardSection;
