import React from "react";
import Heading from "../components/Heading";
import SettingsDetail from "../components/SettingsDetail";

const Settings = () => {
	return (
		<div className='col-md-10 p-4 mt-5'>
			<div className='mx-5 px-5'>
				<Heading heading='h4'>Settings</Heading>
				<div className='container w-75'>
					<SettingsDetail />
				</div>
			</div>
		</div>
	);
};

export default Settings;
