// Routes
const HOME = "/";
const USERS = "/users"; //User List
const USER_ID = "/users/:userId"; // Dashboard per user
const ZOOM_USERS = "/zoom/users"; //Zoom Users List
// const ZOOM_RECORDINGS = "/zoom/users/:zoomUserId/recordings"; //Zoom recordings list
const ZOOM_MEETINGS = "/zoom/users/:zoomUserId/meetings"; //Zoom meeting list
const ZOOM_BOOKING = "/zoom/users/:zoomUserId/meetings/create"; //Zoom meeting booking
const ZOOM_MEETING_DASHBOARD = "/zoom/meetings/dashboard"; //Dashboard per meeting
const ZOOM_MEETING_DASHBOARD_PER_USER = "/zoom/meetings/dashbordPerUser"; //Dashboard per meeting
const SETTINGS = "/settings";  //Settings
const CALENDER = "/calender";  //Calender

const Routes = {
	home: HOME,
	users: USERS,
	userId: USER_ID,
	zoomUsers: ZOOM_USERS,
	// zoomRecording: ZOOM_RECORDINGS,
	zoomMeetings: ZOOM_MEETINGS,
	// zoomRecording: ZOOM_RECORDINGS,
	zoomBooking: ZOOM_BOOKING,
	zoomMeetingDashboard: ZOOM_MEETING_DASHBOARD,
	zoomMeetingDashboardPerUser: ZOOM_MEETING_DASHBOARD_PER_USER,
	settings: SETTINGS,
	calender: CALENDER,
};

export default Routes;
