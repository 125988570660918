import { Auth, API } from "aws-amplify";

export async function getZoomUsers() {
	const userId = (await Auth.currentUserInfo()).attributes.sub;

	const apiName = "ZoomUsersAPI";
	const path = "?userId=" + userId;
	const myInit = {
		headers: {
			Authorization: `Bearer ${(await Auth.currentSession())
				.getIdToken()
				.getJwtToken()}`,
		},
	};

	return await API.get(apiName, path, myInit);
}

export async function getZoomUsersWithOffset(offset, perPage) {
	const userId = (await Auth.currentUserInfo()).attributes.sub;

	const apiName = "ZoomUsersAPI";
	const path = 
		"?userId=" + 
		userId + 
		"&offset=" +
		offset +
		"&perPage=" +
		perPage;
	const myInit = {
		headers: {
			Authorization: `Bearer ${(await Auth.currentSession())
				.getIdToken()
				.getJwtToken()}`,
		},
	};

	return await API.get(apiName, path, myInit);
}