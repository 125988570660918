import { getZoomUsers, getZoomUsersWithOffset } from "../../api/ZoomUsers";
import {
	fetchZoomUsersSuccess,
	fetchZoomUsersFailure,
	fetchZoomUsersRequest,
} from "./zoomUsersAction";

export const fetchZoomUsers = () => {
	return (dispatch) => {
		//Initializing the request
		dispatch(fetchZoomUsersRequest());
		//Calling the api
		getZoomUsers()
			.then((response) => {
				// response is the zoom meeting report
				const zoomUsers = response.zoom_users;
				// console.log("Zoom");
				// console.log(zoomUsers);
				dispatch(fetchZoomUsersSuccess(zoomUsers));
			})
			.catch((error) => {
				// error.message is the error message
				dispatch(fetchZoomUsersFailure(error.message));
			});
	};
};

export const fetchZoomUsersWithOffset = (offset, perPage) => {
	return (dispatch) => {
		//Initializing the request
		dispatch(fetchZoomUsersRequest());
		//Calling the api
		getZoomUsersWithOffset(offset, perPage)
			.then((response) => {
				// response is the zoom meeting report
				const zoomUsers = response.zoom_users;
				// console.log("Zoom");
				// console.log(zoomUsers);
				dispatch(fetchZoomUsersSuccess(zoomUsers));
			})
			.catch((error) => {
				// error.message is the error message
				dispatch(fetchZoomUsersFailure(error.message));
			});
	};
};
