export const fetchUsersRequest = () => {
	return {
		type: "FETCH_USERS_REQUEST",
	};
};

export const fetchUsersSuccess = (data) => {
	return {
		type: "FETCH_USERS_SUCCESS",
		payload: data,
	};
};

export const fetchUsersFailure = (error) => {
	return {
		type: "FETCH_USERS_FAILURE",
		payload: error,
	};
};
