import React from "react";

const Heading = (props) => {
	const style = {
		fontWeight: 700,
	};
	return (
		<h1 className={`${props.heading} text-center my-2 mb-5`} style={style}>
			{props.children}
		</h1>
	);
};

export default Heading;
