import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers, fetchUsersWithOffset } from "../store/users/usersEffect";

import Heading from "../components/Heading";
import Card from "../components/Card";
import Table from "../components/Table";

import { USER_TABLE } from "../utils/schema";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { fetchZoomUserSuccess } from "../store/zoomUser/zoomUserAction";

const iconStyle = {
	fontSize: 25,
	color: "grey",
};
//FIXME:Slow Working because of fetching data every time
const User = () => {
	const PER_PAGE = 20;

	const dispatch = useDispatch();
	const [page, setPage] = useState(0);

	useEffect(() => {
		dispatch(fetchUsersWithOffset(page, 100000));
	}, []);

	const usersObj = useSelector((state) => state.usersReducer.users);

	const users =
		usersObj.length != 0 ? usersObj.users.slice(page, page + PER_PAGE) : [];

	let pageCount = 0;

	if ("meta" in usersObj) {
		pageCount = Math.ceil(usersObj.meta.total_count / PER_PAGE);
	}

	let userList = users
		? users.map((user) => {
				return (
					<tr key={user.user_id}>
						<td>{user.user_id}</td>
						<td>
							{user.first_name} {user.last_name}
						</td>
						<td
							onClick={() => {
								dispatch(fetchZoomUserSuccess(user));
							}}
							className='td-actions'>
							<Link
								to={
									"/users/" +
									user.user_id +
									"?name=" +
									user.first_name +
									user.last_name
								}>
								<FontAwesomeIcon
									style={iconStyle}
									icon={faChartLine}
								/>
							</Link>
						</td>
					</tr>
				);
		  })
		: "";

	const handlePageClick = (data) => {
		let selected = data.selected;
		let currentPage = Math.ceil(selected * PER_PAGE);
		setPage(currentPage);
		userList = "";
	};

	const style = {
		backgroundColor: "#ffffff",
	};

	const paginate = userList ? (
		<ReactPaginate
			previousLabel={"previous"}
			nextLabel={"next"}
			breakLabel={"..."}
			breakClassName={"break-me"}
			pageCount={pageCount}
			marginPagesDisplayed={2}
			pageRangeDisplayed={5}
			onPageChange={handlePageClick}
			containerClassName={"pagination justify-content-center"}
			activeClassName={"active"}
			pageClassName='page-item'
			pageLinkClassName='page-link'
			previousClassName='page-item'
			nextClassName='page-item'
			previousLinkClassName='page-link'
			nextLinkClassName='page-link'
			disabledClassName='disabled'
			breakClassName='page-item'
			breakLinkClassName='page-link'
		/>
	) : (
		""
	);

	return (
		<div className='col-md-10 p-4 mt-5'>
			<Heading heading='h4'>User List</Heading>
			<Card>
				<Table schema={USER_TABLE} data={userList} />
			</Card>
			<div className='mt-2'>{paginate}</div>
		</div>
	);
};

export default User;
