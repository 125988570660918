export const fetchZoomMeetingRequest = () => {
	return {
	  type: "FETCH_ZOOM_MEETING_REQUEST"
	}
  }
  
  export const fetchZoomMeetingSuccess = zoomMeeting => {
	return {
	  type: "FETCH_ZOOM_MEETING_SUCCESS",
	  payload: zoomMeeting
	}
  }
  
  export const fetchZoomMeetingFailure = error => {
	return {
	  type: "FETCH_ZOOM_MEETING_FAILURE",
	  payload: error
	}
  }

