import FusionCharts from "fusioncharts";

const plotData = (faceEmotions, userId) => {
	let dateFormat = require("dateformat");
	let data_set = [];

	let start_date = new Date();
	let end_date = new Date();
	end_date.setDate(end_date.getDate());
	start_date.setDate(start_date.getDate() - 5);
	start_date = dateFormat(start_date, "m/d/yyyy H");
	end_date = dateFormat(end_date, "m/d/yyyy H");

	if ("emotions" in faceEmotions && faceEmotions.emotions.length != 0) {
		for (let i = 0; i < faceEmotions.emotions.length; i++) {
			const userIdInEmotions = faceEmotions.emotions[i].user_id;

			if (userIdInEmotions != userId) {
				continue;
			}

			let data = [];
			data.push(
				dateFormat(
					new Date(faceEmotions.emotions[i].timestamp),
					"m/d/yyyy H"
				)
			); // H:M:ss form seconds
			data.push(
				faceEmotions.emotions[i].happy -
					(faceEmotions.emotions[i].angry +
						faceEmotions.emotions[i].fear +
						faceEmotions.emotions[i].disgust +
						faceEmotions.emotions[i].sad)
			);
			data_set.push(data);
		}
	}

	const dataFetch = data_set;
	const schemaFetch = [
		{
			name: "Time",
			type: "date",
			format: "%-m/%-d/%Y %H", // %H:%M:%S for seconds
		},
		{
			name: "Valence",
			type: "number",
		},
	];
	const fusionTable = new FusionCharts.DataStore().createDataTable(
		dataFetch,
		schemaFetch
	);
	const dataSource = {
		type: "timeseries",
		renderAt: "container",
		width: "100%",
		height: "600",
		dataSource: {
			data: fusionTable,
			chart: {
				//   "palettecolors":"dd6f6e,ffcf8e,f48f57,1d5fbf,45379b,2097b7,934e9f,e66b9d"
			},
			caption: {},
			subcaption: {},
			legend: {
				position: "top",
				alignment: "middle",
			},
			yaxis: [
				{
					plot: [
						{
							value: "Valence",
							type: "column",
							connectnulldata: true,
						},
					],
				},
			],
			xaxis: {
				// showclippingcue: "1",
				autoClipNullData: "Hour",
				// "autoClipMultiplier": 4,
				// initialinterval: {
				//   from: start_date,
				//   to: end_date
				// }
			},
		},
	};
	return dataSource;
};

export default plotData;
