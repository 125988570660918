import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchZoomUsers, fetchZoomUsersWithOffset } from "../store/zoomUsers/zoomUsersEffect";

import Heading from "../components/Heading";
import Card from "../components/Card";
import Table from "../components/Table";

import { ZOOM_USERS } from "../utils/schema";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";

const iconStyle = {
	fontSize: 25,
	color: "grey",
};

const ZoomUsers = () => {
	const PER_PAGE = 10;

	const dispatch = useDispatch();
	const [page, setPage] = useState(0);


	useEffect(() => {
		dispatch(fetchZoomUsersWithOffset(page + 1, PER_PAGE));
	}, [page]);

	const zoomUsersObj = useSelector(
		(state) => state.zoomUsersReducer.zoomUsers
	);

	const zoomUsers = zoomUsersObj.length != 0 ? zoomUsersObj.users : [];

	let pageCount = 0;

	if ("total_records" in zoomUsersObj) {
		pageCount = Math.ceil(zoomUsersObj.total_records / PER_PAGE);
	}

	const zoomUserList = zoomUsers
		? zoomUsers.map((zoomUser) => {
			return (
				<tr key={zoomUser.id}>
					<td>{zoomUser.id}</td>
					<td>{zoomUser.email}</td>
					<td>{zoomUser.first_name}</td>
					<td className='td-actions'>
						<Link
							to={
								"/zoom/users/" + zoomUser.id + "/meetings"
							}>
							<FontAwesomeIcon
								style={iconStyle}
								icon={faVideo}
							/>
						</Link>
					</td>
				</tr>
			);
		})
		: "";

	const handlePageClick = (data) => {
		let selected = data.selected;
		
		// let currentPage = Math.ceil(selected / PER_PAGE);
		// console.log(selected)
		// console.log(currentPage)
		setPage(selected);
	};

	const style = {
		backgroundColor: "#ffffff",
	};

	const paginate = zoomUserList ? (
		<ReactPaginate
			previousLabel={"previous"}
			nextLabel={"next"}
			breakLabel={"..."}
			breakClassName={"break-me"}
			pageCount={pageCount}
			marginPagesDisplayed={2}
			pageRangeDisplayed={5}
			onPageChange={handlePageClick}
			containerClassName={"pagination justify-content-center"}
			activeClassName={"active"}
			pageClassName='page-item'
			pageLinkClassName='page-link'
			previousClassName='page-item'
			nextClassName='page-item'
			previousLinkClassName='page-link'
			nextLinkClassName='page-link'
			disabledClassName='disabled'
			breakClassName='page-item'
			breakLinkClassName='page-link'
		/>
	) : (
		""
	);

	return (
		<div className='col-md-10 p-4 mt-5'>
			<Heading heading='h4'>Zoom User List</Heading>
			<Card>
				<Table schema={ZOOM_USERS} data={zoomUserList} />
			</Card>
			<div className='mt-2'>{paginate}</div>
		</div>
	);
};

export default ZoomUsers;
