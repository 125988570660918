import { combineReducers } from "redux";

import faceEmotionsReducer from "./faceEmotions/faceEmotionsReducer";
import appUsagesReducer from "./appUsages/appUsagesReducer";
import usersReducer from "./users/usersReducer";
import zoomUsersReducer from "./zoomUsers/zoomUsersReducer";
import zoomRecordingsReducer from "./zoomRecordings/zoomRecordingsReducer";
import zoomUserReducer from "./zoomUser/zoomUserReducer";
import zoomMeetingReportReducer from "./zoomMeetingReport/zoomMeetingReportReducer";
import zoomMeetingsReducer from "./zoomMeetings/zoomMeetingsReducer";

export default combineReducers({
	faceEmotionsReducer,
	appUsagesReducer,
	usersReducer,
	zoomUsersReducer,
	zoomRecordingsReducer,
	zoomUserReducer,
	zoomMeetingReportReducer,
	zoomMeetingsReducer,
});
