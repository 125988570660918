import React from "react";
import { Line } from "react-chartjs-2";
import { config } from "./config";
import plotValenceScore from "./helper";

const valenceScore = (props) => {
	//Plotting data
	const { data, range_max, range_min } = plotValenceScore(
		props.dataset,
		props.userId
	);
	// Congiguration
	const options = config(range_min, range_max);

	const style = {
		fontWeight: "bold",
		color: "#6d5e57",
	};

	return (
		<div className='card text-center'>
			<div className='pt-3' style={style}>
				Valence Score
			</div>
			<div className='card-body'>
				<Line
					data={data}
					width={1000}
					height={400}
					options={options}
					type={"line"}
				/>
			</div>
		</div>
	);
};

export default valenceScore;
