import { getUsers, getUsersWithOffset, getRegisteredUsers } from "../../api/Users";
import {
	fetchUsersRequest,
	fetchUsersFailure,
	fetchUsersSuccess,
} from "./usersAction";

export const fetchUsers = () => {
	return (dispatch) => {
		//Initializing Request
		dispatch(fetchUsersRequest());
		//Calling the api
		getUsers()
			.then((response) => {
				// response is the users
				dispatch(fetchUsersSuccess(response));
			})
			.catch((error) => {
				// error.message is the error message
				dispatch(fetchUsersFailure(error.message));
			});
	};
};

export const fetchRegisteredUsers = () => {
	return (dispatch) => {
		//Initializing Request
		dispatch(fetchUsersRequest());
		//Calling the api
		getRegisteredUsers()
			.then((response) => {
				// response is the users
				console.log(response)
				dispatch(fetchUsersSuccess(response));
			})
			.catch((error) => {
				// error.message is the error message
				dispatch(fetchUsersFailure(error.message));
			});
	};
};

export const fetchUsersWithOffset = (offset, perPage) => {
	return (dispatch) => {
		//Initializing Request
		dispatch(fetchUsersRequest());
		//Calling the api
		getUsersWithOffset(offset, perPage)
			.then((response) => {
				// response is the users
				const users = response.users;
				dispatch(fetchUsersSuccess(users));
			})
			.catch((error) => {
				// error.message is the error message
				dispatch(fetchUsersFailure(error.message));
			});
	};
};
