import { Auth, API } from "aws-amplify";

export async function getZoomMeetingReport(zoomMeetingId) {
	const userId = (await Auth.currentUserInfo()).attributes.sub;

	const apiName = "ZoomMeetingReport";
	const path = "?zoomMeetingId=" + zoomMeetingId + "&userId=" + userId;
	const myInit = {
		headers: {
			Authorization: `Bearer ${(await Auth.currentSession())
				.getIdToken()
				.getJwtToken()}`,
		},
	};

	return await API.get(apiName, path, myInit);
}
