import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchZoomMeetingReport } from "../../store/zoomMeetingReport/zoomMeetingReportEffect";

import {
	fetchFaceEmotions,
	fetchFaceEmotionsInBetween,
} from "../../store/faceEmotions/faceEmotionsEffect";

import Heading from "../../components/Heading";
import Info from "../../components/Info";
import ChartSection from "../../components/ChartsPerMeeting";

const DashboardPerMeeting = () => {
	//Fetching faceEmotions
	const dispatch = useDispatch();
	const faceEmotions = useSelector(
		(state) => state.faceEmotionsReducer.faceEmotions
	);
	const zoomMeetingReport = useSelector(
		(state) => state.zoomMeetingReportReducer.zoomMeetingReport
	);

	const zoomMeeting = zoomMeetingReport.zoom_meeting_report;

	// const zoomMeetingId = decodeURI(window.location.search.substring(15));
	const params = new URLSearchParams(window.location.search);
	const zoomMeetingId = params.get('zoomMeetingId');
	const from = params.get('from');
	const to = params.get('to');
	
	useEffect(() => {
		dispatch(fetchFaceEmotionsInBetween(from, to));
		dispatch(fetchZoomMeetingReport(zoomMeetingId));
	}, []);

	return (
		<div className='col-md-10 p-4'>
			<Heading heading='h5'>
				IBY analyzes online communication every seconds.
			</Heading>
			<Info zoomMeeting={zoomMeeting} />
			<ChartSection faceEmotions={faceEmotions} />
		</div>
	);
};

export default DashboardPerMeeting;
