export const config = (range_min, range_max, faceEmotions) => {
	return {
		scales: {
			xAxes: [
				{
					type: "time",
				},
			],
			yAxes: [
				{
					ticks: {
						min: -1,
						max: 1,
					},
				},
			],
		},
		pan: {
			enabled: true,
			mode: "x",
			speed: 0.1,
			rangeMin: {
				x: range_min,
			},
			rangeMax: {
				x: range_max,
			},
		},
		zoom: {
			enabled: true,
			mode: "x",
			speed: 0.1,
			rangeMin: {
				x: range_min,
			},
			rangeMax: {
				x: range_max,
			},
		},
		animation: false,
		onClick: function (evt, element) {
			if (element.length > 0) {
				const userId = faceEmotions.emotions[element[0]._index].user_id;
				const params = new URLSearchParams(window.location.search);
				const from = params.get("from");
				const to = params.get("to");
				const zoomMeetingId = params.get("zoomMeetingId");
				const url = "/zoom/meetings/dashbordPerUser?" + "zoomMeetingId=" + zoomMeetingId + "&userId=" + userId + "&from=" + from + "&to=" + to;
				window.open(url);

			}
		}
	};
};
