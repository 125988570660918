// Define Schema here for different types of table

//User
export const USER_TABLE = [
	{ id: 1, content: "ID" },
	{ id: 2, content: "NAME" },
	{ id: 3, content: "DASHBOARD" },
];

//Zoom User
export const ZOOM_USERS = [
	{ id: 1, content: "ID" },
	{ id: 2, content: "EMAIL" },
	{ id: 3, content: "FIRST NAME" },
	{ id: 4, content: "RECORDING" },
];

//Zoom Recording List
export const ZOOM_RECORDING = [
	{id:1 , content:"ID"},
	{id:2 , content:"TITLE"},
	{id:3 , content:"START TIME"},
	{id:4 , content:"DURATION"},
	{id:5 , content:"JOIN URL"},
	{id:6 , content:"DASHBOARD"},
]
