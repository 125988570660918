import React from "react";
import Card from "../Card";
import Loading from "../../assets/loading.gif";

const SummaryCard = (props) => {
	const emotion_icon = {
		width: "64px",
		height: "64px",
		marginTop: "-80px",
	};

	const icon_container = {
		display: "flex",
	};

	const style = {
		fontWeight: "bold",
	};

	const image =
		props.type === "Negative" ? (
			<div style={icon_container}>
				<img
					className='mx-auto'
					src={props.disgustIcon}
					style={emotion_icon}
					alt='Card cap'
				/>
				<img
					className='mx-auto'
					src={props.angryIcon}
					style={emotion_icon}
					alt='Card cap'
				/>
				<img
					className='mx-auto'
					src={props.sadIcon}
					style={emotion_icon}
					alt='Card cap'
				/>
				<img
					className='mx-auto'
					src={props.fearIcon}
					style={emotion_icon}
					alt='Card cap'
				/>
			</div>
		) : (
			<div style={icon_container}>
				<img
					className='mx-auto'
					src={props.icon}
					style={emotion_icon}
					alt='Card cap'
				/>
			</div>
		);

	const dataType = props.type === "Face Detection Time" ? "sec" : "%";

	if (props.loading) {
		return (
			<Card>
				<div>
					{image}
					<img className="img-fluid center-block" src={Loading} ></img>
				</div>
			</Card>
		);
	}

	return (
		<Card>
			{image}
			<div className='card-body text-center'>
				<h5 style={style}>{props.type}</h5>
				<h6 className='mb-2'>This week</h6>
				<p className='h1 card-text' style={style}>
					{props.summary.thisWeek}
					<span className='h4'>{dataType}</span>
				</p>
			</div>
			<div className='text-center'>
				<hr className='mb-3' />
				<div className='row'>
					<div className='col-md-6'>
						<h6 className='card-subtitle mb-2'>Last week</h6>
						<p className='h2 card-text' style={style}>
							{props.summary.lastWeek}
							<span className='h5'>{dataType}</span>
						</p>
					</div>
					<div className='col-md-6'>
						<h6 className='card-subtitle mb-2'>Last month</h6>
						<p className='h2 card-text' style={style}>
							{props.summary.lastMonth}
							<span className='h5'>{dataType}</span>
						</p>
					</div>
				</div>
			</div>
		</Card>
	);
};

export default SummaryCard;
