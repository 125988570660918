// DO NOT EDIT this file , unless you know what you are doing

const aws = {
	Auth: {
		region: "ap-northeast-1",
		userPoolId: "ap-northeast-1_lsNPdwGp0",
		userPoolWebClientId: "lu75ol96c3p6an6ofo5rpo8r9",
	},
	API: {
		endpoints: [
			{
				name: "FaceEmotionsAPI",
				endpoint:
					"https://2w302jx729.execute-api.ap-northeast-1.amazonaws.com/emotions",
				region: "ap-northeast-1",
			},
			{
				name: "UsersAPI",
				endpoint:
					"https://2w302jx729.execute-api.ap-northeast-1.amazonaws.com/users",
				region: "ap-northeast-1",
			},
			{
				name: "RegisteredUsersAPI",
				endpoint:
					"https://2w302jx729.execute-api.ap-northeast-1.amazonaws.com/users/registered",
				region: "ap-northeast-1",
			},
			{
				name: "appUsagesAPI",
				endpoint:
					"https://2w302jx729.execute-api.ap-northeast-1.amazonaws.com/usages",
				region: "ap-northeast-1",
			},
			{
				name: "ZoomUsersAPI",
				endpoint:
					"https://2w302jx729.execute-api.ap-northeast-1.amazonaws.com/zoom/users",
				region: "ap-northeast-1",
			},
			{
				name: "ZoomRecordingsAPI",
				endpoint:
					"https://2w302jx729.execute-api.ap-northeast-1.amazonaws.com/zoom/users/recordings",
				region: "ap-northeast-1",
			},
			{
                name: "ZoomMeetingsAPI",
                endpoint: "https://2w302jx729.execute-api.ap-northeast-1.amazonaws.com/zoom/users/meetings",
                region: "ap-northeast-1"
            },
			{
				name: "ZoomMeetingReport",
				endpoint:
					"https://2w302jx729.execute-api.ap-northeast-1.amazonaws.com/zoom/report/meetings",
				region: "ap-northeast-1",
			},
			{
                name: "ZoomScheduledMeetingsAPI",
                endpoint: "https://2w302jx729.execute-api.ap-northeast-1.amazonaws.com/zoom/users/scheduledMeetings",
                region: "ap-northeast-1"
            }
		],
	},
};

export default aws;
