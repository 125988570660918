export const fetchFaceEmotionsRequest = () => {
	return {
	  type: "FETCH_FACE_EMOTIONS_REQUEST"
	}
  }
  
  export const fetchFaceEmotionsSuccess = data => {
	return {
	  type: "FETCH_FACE_EMOTIONS_SUCCESS",
	  payload: data
	}
  }
  
  export const fetchFaceEmotionsFailure = error => {
	return {
	  type: "FETCH_FACE_EMOTIONS_FAILURE",
	  payload: error
	}
  }
