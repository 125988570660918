import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import ZoomBookingDetail from "./ZoomBookingDetail";

import Modal from "react-modal";
Modal.setAppElement("#root");

const ZoomBooking = ({ slot, modalIsOpen, setIsOpen }) => {

	// const [modalIsOpen, setIsOpen] = useState(0);

	return (
		// <div className='col-md-10 p-4 mt-5'>
		// 	<div className='mx-5 px-5'>
		// 		<Heading heading='h4'>Zoom booking</Heading>
		// 		<div className='container w-75'>
		// 			<ZoomBookingDetail />
		// 		</div>
		// 	</div>
		// </div>

		<Modal
			isOpen={modalIsOpen}
			className="Modal"
			overlayClassName="Overlay"
		>
			{/* <div><button onClick={() => setIsOpen(false)}>Close Modal</button></div> */}
			<Heading heading='h4'>Zoom booking</Heading>
			<ZoomBookingDetail slot={slot} setIsOpen={setIsOpen}/>

		</Modal>

	);
};

export default ZoomBooking;
