import React, { useEffect, useState } from "react";
import Card from "./Card";
import { getZoomSettings, updateZoomSettings } from "../api/ZoomSettings";

const SettingsDetail = (props) => {
	//Setting Zoomkey and ZoomSecretKey
	const [zoomKey, setZoomKey] = useState("");
	const [zoomSecret, setZoomSecret] = useState("");

	//Fetching Zoom Key and Zoom Secret Key
	useEffect(() => {
		getZoomSettings()
			.then((zoomSettings) => {
				setZoomKey(zoomSettings.zoomKey);
				setZoomSecret(zoomSettings.zoomSecret);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const handleKeyChange = (event) => {
		setZoomKey(event.target.value);
	};

	const handleSecretChange = (event) => {
		setZoomSecret(event.target.value);
	};

	// Sending Data back to server
	const handleSubmit = (event) => {
		event.preventDefault();
		updateZoomSettings(zoomKey, zoomSecret);
		alert("Registration Successful!");
	};

	return (
		<Card>
			<form onSubmit={handleSubmit}>
				<div className='form-group row mb-4'>
					<p className='col-sm-2'>Zoom key</p>
					<div className='col-sm-10'>
						<input
							type='text'
							className='form-control'
							placeholder='key'
							value={zoomKey}
							onChange={handleKeyChange}
						/>
					</div>
				</div>

				<div className='form-group row mb-4'>
					<p className='col-sm-2'>Zoom sercret</p>
					<div className='col-sm-10'>
						<input
							className='form-control'
							type='text'
							placeholder='sercret'
							value={zoomSecret}
							onChange={handleSecretChange}
						/>
					</div>
				</div>

				<button
					type='submit'
					className='btn d-block mx-auto text-white text-center px-4 mt-5 login-submit-bg-alpha'>
					SEND
				</button>
			</form>
		</Card>
	);
};

export default SettingsDetail;
