import React from "react";

// Generic component for holding things like graphs , userList , summaryCard etc.
const List = (props) => {
	return (
		<div className='card py-4 text-center'>
			<div className='card-body'>
				<div className='w-100'>{props.children}</div>
			</div>
		</div>
	);
};

export default List;
