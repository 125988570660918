import { Auth, API } from "aws-amplify";

export async function getFaceEmotions() {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "FaceEmotionsAPI";
	const path = "?accountId=" + accountId;
	const myInit = {
		headers: {
			Authorization: `Bearer ${(await Auth.currentSession())
				.getIdToken()
				.getJwtToken()}`,
		},
	};

	return await API.get(apiName, path, myInit);
}

export async function getFaceEmotionsInBetween(from, to) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "FaceEmotionsAPI";
	const path = "?accountId=" + accountId + "&from=" + from + "&to=" + to;
	const myInit = {
		headers: {
			Authorization: `Bearer ${(await Auth.currentSession())
				.getIdToken()
				.getJwtToken()}`,
		},
	};

	return await API.get(apiName, path, myInit);
}
