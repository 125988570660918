import React, { useEffect, useState } from "react";
import Card from "./Card";

import { Link, useParams } from "react-router-dom";
import moment from 'moment-timezone';

import { useForm, Controller } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"

import Select from 'react-select';

import { bookZoomMeeting } from "../api/ZoomBooking";
import { getRegisteredUsers } from "../api/Users";
import { getZoomUsersWithOffset } from "../api/ZoomUsers";

const ZoomBookingDetail = ({ slot, setIsOpen }) => {
	const { zoomUserId } = useParams();
	
	const { control, register, handleSubmit, watch, formState: { errors } } = useForm({
		defaultValues: {
			meetingDate: slot && slot.start ? slot.start : new Date(),
			duration: slot && slot.duration ? slot.duration : 0,
		}
	});

	// For participnats
	const [participants, setParticipants] = useState([]);
	const [participantOptions, setParticipantOptions] = useState([]);

	// For meeting host
	const [host, setHost] = useState("");
	const [zoomUserOptions, setZoomUserOptions] = useState([]);

	useEffect(() => {
		getRegisteredUsers()
			.then((users) => {
				setParticipantOptions(users.users.map(user => ({ value: user.user_id, label: user.name })));
			})
			.catch((err) => {
				console.log(err);
			});

		getZoomUsersWithOffset(1, 100000)
			.then((zoomUsers) => {
				console.log(zoomUsers)
				setZoomUserOptions(zoomUsers.zoom_users.users.map(user => ({ value: user.id, label: user.first_name + user.last_name })));
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const onSubmit = data => {
		const meetingDate = moment(data.meetingDate).format("YYYY-MM-DD[T]HH:mm:ss");
		const topic = data.topic;
		const duration = data.duration;

		const zoomUserIdForBooking = zoomUserId ? zoomUserId : host.value
		console.log(zoomUserIdForBooking)

		bookZoomMeeting(zoomUserIdForBooking, topic, duration, meetingDate, participants.map(participant => participant.value))
			.then(response => {
				console.log(response);
				alert('Successfully booked!');
			})
			.catch(err => {
				console.log(err);
			});
	};

	return (
		<Card>
			<form onSubmit={handleSubmit(onSubmit)}>
				{/* register your input into the hook by invoking the "register" function */}
				<div className="form-group mt-2 row">
					<p className="col-sm-2 text-right">Topic</p>
					<div className="col-sm-6">
						<input className="form-control" defaultValue="Please put topic" {...register("topic")} />
					</div>
				</div>

				{/* include validation with required or other standard HTML validation rules */}


				<div className="form-group mt-2 row">
					<p className="col-sm-2 text-right">Duration(min)</p>
					<div className="col-sm-2">

						<input className="form-control"
							// defaultValue={slot.duration}
							{...register("duration", { required: true })}
						/>

						{/* errors will return when field validation fails  */}
						{errors.exampleRequired && <span>This field is required</span>}
					</div>
				</div>

				<div className="form-group mt-2 row">
					<p className="col-sm-2 text-right">Date</p>
					<div className="col-sm-2">
						<Controller
							control={control}
							name="meetingDate"
							render={({ field: { onChange, onBlur, value } }) => (
								<ReactDatePicker className="form-control"
									dateFormat="yyyy-MM-dd HH:mm"
									showTimeSelect
									timeFormat="HH:mm"
									timeIntervals={15}
									timeCaption="time"
									onChange={e => {
										console.log(e)
										onChange(e)
									}
									}
									onBlur={onBlur}
									selected={value}
								/>
							)}
						/>
					</div>
				</div>


				<div className="form-group mt-2 row">
					<p className="col-sm-2 text-right">Meeting host</p>
					<Select
						className="col-sm-6"
						options={zoomUserOptions}
						isSearchable
						onChange={(value) => {
							console.log(value)
							setHost(value);
						}}
					/>
				</div>


				{
					zoomUserId ? <div></div> :
						<div className="form-group mt-2 row">
							<p className="col-sm-2 text-right">Participants</p>
							<Select
								className="col-sm-6"
								options={participantOptions}
								isMulti
								isSearchable
								onChange={(value) => {
									setParticipants(value);
								}}
							/>
						</div>
				}


				<div className="row">
					<p className="col-sm-2 text-right"></p>

					<div className="col-sm-8">
						{/* <Link className='btn text-white px-4 mt-5 login-submit-bg-alpha btn-lg'
							to={
								"/zoom/users/" + zoomUserId + "/meetings"
							}>
							Cancel
						</Link> */}

						<button
							type="button"
							className='btn text-white px-4 mt-5 login-submit-bg-alpha btn-lg'
							onClick={() => setIsOpen(false)}
						>
							Cancel
						</button>

						<button type="submit" className="btn btn-primary px-6 mt-5 btn-lg">Book</button>

					</div>
				</div>
			</form>
		</Card>
	);
};

export default ZoomBookingDetail;