import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	fetchFaceEmotions,
	fetchFaceEmotionsInBetween,
} from "../../store/faceEmotions/faceEmotionsEffect";

import {
	fetchRegisteredUsers,
} from "../../store/users/usersEffect";

import Heading from "../../components/Heading";
import SummaryCardSection from "../../components/Summary";
import ChartSection from "../../components/Charts";

const Dashboard = () => {
	//Fetching faceEmotions
	const faceEmotions = useSelector(
		(state) => state.faceEmotionsReducer.faceEmotions
	);

	const users = useSelector(
		(state) => state.usersReducer
	);

	const dispatch = useDispatch();

	// const date = new Date();
	// const to = date.getDate();
	// const from = date.getDate() - 1;

	useEffect(() => {
		dispatch(fetchFaceEmotions());
		dispatch(fetchRegisteredUsers());
	}, []);

	console.log(users);

	return (
		<div className='col-md-10 p-4'>
			<Heading heading='h5'>
				IBY analyzes online communication every seconds.
			</Heading>
			<SummaryCardSection faceEmotions={faceEmotions} />
			<ChartSection faceEmotions={faceEmotions} users={users}/>
		</div>
	);
};

export default Dashboard;
