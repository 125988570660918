import moment from 'moment-timezone';

const plotData = (faceEmotions) => {
	let users = new Set();
	let usersEngagement = {};

	// const duration = zoomMeetingReport.duration * 60;

	const params = new URLSearchParams(window.location.search);
	const from = params.get("from");
	const to = params.get("to");
	const duration = moment(to.split('T').join(' ')).diff(moment(from.split('T').join(' ')), 'minutes');

	if ("emotions" in faceEmotions && faceEmotions.emotions.length != 0) {

		for (let i = 0; i < faceEmotions.emotions.length; i++) {
			users.add(faceEmotions.emotions[i].user_id);
		}

		users.forEach((a) => {
			usersEngagement[a] = new Set();
		});

		for (let i = 0; i < faceEmotions.emotions.length; i++) {
			const hhmm = faceEmotions.emotions[i].timestamp.split(' ')[1].substr(0, 4);
			usersEngagement[faceEmotions.emotions[i].user_id].add(hhmm)
		}

	}

	let rows = parseInt(users.size / 4);
	let cols = 4;
	if (users.size % 4 != 0) {
		rows += 1;
	}
	let dataset = [];
	let cnt = 0;
	let r = 1;
	let c = 1;

	users.forEach((u) => {
		let data = {};
		let engagement = "low";
		
		// check face detaction every 10min
		const er = usersEngagement[u].size * 100 / (duration / 10);

		if (er > 25 && er < 50) {
			engagement = "middle";
		} else if (er > 50) {
			engagement = "high";
		}
		data = {
			rowid: r.toString(),
			columnid: (c++).toString(),
			displayvalue: "User" + ++cnt,
			value: u,
			brLabel: u,
			colorrangelabel: engagement,
		};

		if (cnt % 4 == 0) {
			r++;
			c = 1;
		}

		dataset.push(data);
	});

	let rows_data = [];
	let cols_data = [];
	for (let i = 0; i < rows; i++) {
		let d = {
			id: (i + 1).toString(),
		};
		rows_data.push(d);
	}

	for (let i = 0; i < cols; i++) {
		let d = {
			id: (i + 1).toString(),
		};
		cols_data.push(d);
	}

	const dataSource = {
		colorrange: {
			gradient: "0",
			color: [
				{
					code: "#F2726F",
					minvalue: "0",
					maxvalue: "30",
					label: "low",
				},
				{
					code: "#FFC533",
					minvalue: "30",
					maxvalue: "60",
					label: "middle",
				},
				{
					code: "#62B58F",
					minvalue: "60",
					maxvalue: "100",
					label: "high",
				}
			],
		},
		dataset: [
			{
				data: dataset,
			},
		],
		columns: {
			column: cols_data,
		},
		rows: {
			row: rows_data,
		},
		chart: {
			animation: "1",
			theme: "fusion",
			caption: "Users",
			subcaption: " Engagement Map",
			showLabels: "0",
			showvalues: "1",
			mapbycategory: "1",
			valueFontColor: "#ffffff",
			plottooltext:
				"User <b>$value</b> engagement was $colorrangelabel",
		},

	};
	return dataSource;
};

export default plotData;
