import { Auth } from "aws-amplify";

export async function getZoomSettings() {
	const attributes = (await Auth.currentUserInfo()).attributes;

	const zoomSettings = {
		zoomKey: attributes["custom:zoom_key"],
		zoomSecret: attributes["custom:zoom_secret"],
	};
    console.log(zoomSettings);
	return zoomSettings;

}

export async function updateZoomSettings(key, secret) {
	const zoomSettings = {
		"custom:zoom_key": key,
		"custom:zoom_secret": secret,
	};
	const user = await Auth.currentAuthenticatedUser();

	await Auth.updateUserAttributes(user, zoomSettings)
		.then((response) => {})
		.catch((err) => {});
}
