import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.scss";

import Amplify from "aws-amplify";
import aws from "./utils/aws-exports";
import { withAuthenticator } from "@aws-amplify/ui-react";

import { Provider } from "react-redux";
import store from "./store/rootStore";

import NavigationDrawer from "./components/NavigationDrawer";

import Dashboard from "./containers/Dashbaord/Dashboard";
import DashboardPerUser from "./containers/Dashbaord/DashbaordPerUser";
import DashboardPerMeeting from "./containers/Dashbaord/DashboardPerMeeting";
import DashboardPerMeetingAndUser from "./containers/Dashbaord/DashboardPerMeetingAndUser";
import User from "./containers/User";
import ZoomUsers from "./containers/ZoomUsers";
import ZoomRecording from "./containers/ZoomRecording";
import ZoomMeetings from "./containers/ZoomMeetings";
// import ZoomBooking from "./containers/ZoomBooking";
import Settings from "./containers/Settings";
import Calender from "./containers/Calender/Calender";

import routes from "./utils/routes";

// Initializing AWS Amplify
Amplify.configure(aws);

//All routes are defines in "./utils/routes.js"
const App = () => {
	return (
		<Provider store={store}>
			<Router>
				<div className='container-fluid'>
					<div className='row'>
						<NavigationDrawer />
						<Switch>
							<Route
								exact
								path={routes.home}
								component={Dashboard}
							/>
							<Route
								exact
								path={routes.userId}
								component={DashboardPerUser}
							/>
							<Route
								exact
								path={routes.zoomMeetingDashboard}
								component={DashboardPerMeeting}
							/>
							<Route
								exact
								path={routes.zoomMeetingDashboardPerUser}
								component={DashboardPerMeetingAndUser}
							/>
							<Route exact path={routes.users} component={User} />

							<Route
								exact
								path={routes.zoomUsers}
								component={ZoomUsers}
							/>
							<Route
								exact
								path={routes.zoomMeetings}
								component={ZoomMeetings}
							/>
							
							<Route
								exact
								path={routes.settings}
								component={Settings}
							/>
							{/* <Route
								exact
								path={routes.zoomBooking}
								component={ZoomBooking}
							/> */}
							<Route
								exact
								path={routes.calender}
								component={Calender}
							/>
						</Switch>
					</div>
				</div>
			</Router>
		</Provider>
	);
};

export default withAuthenticator(App); // withAuthenticator() automatically adds auth facility.
