import moment from "moment";

export const calculateSummary = (faceEmotions, userId) => {
	const firstDayOfThisWeek = moment().startOf("isoWeek").format("YYYY-MM-DD");
	const lastDayOfThisWeek = moment().endOf("isoWeek").format("YYYY-MM-DD");

	const firstDayOfLastWeek = moment()
		.add("isoWeek", -1)
		.startOf("isoWeek")
		.format("YYYY-MM-DD");
	const lastDayOfLastWeek = moment()
		.add("isoWeek", -1)
		.endOf("isoWeek")
		.format("YYYY-MM-DD");

	const firstDayOfLastMonth = moment()
		.add("month", -1)
		.startOf("month")
		.format("YYYY-MM-DD");
	const lastDayOfLastMonth = moment()
		.add("month", -1)
		.endOf("month")
		.format("YYYY-MM-DD");

	let timeSummary = {};
	let happySummary = {};
	let negativeSummary = {};

	let timeInThisWeek = 0;
	let timeInLastWeek = 0;
	let timeInLastMonth = 0;

	let happyInThisWeekList = [];
	let happyInLastWeekList = [];
	let happyInLastMonthList = [];

	let negativeInThisWeekList = [];
	let negativeInLastWeekList = [];
	let negativeInLastMonthList = [];

	if ("emotions" in faceEmotions && faceEmotions.emotions.length != 0) {
		for (let i = 0; i < faceEmotions.emotions.length; i++) {
			const userIdInEmotions = faceEmotions.emotions[i].user_id;
			if (userId != null) {
				if (userIdInEmotions != userId) {
					continue;
				}
			}

			let timestamp = moment(faceEmotions.emotions[i].timestamp);
			let happy = faceEmotions.emotions[i].happy;
			let negative =
				faceEmotions.emotions[i].angry +
				faceEmotions.emotions[i].fear +
				faceEmotions.emotions[i].disgust +
				faceEmotions.emotions[i].sad;

			if (
				timestamp.isBetween(
					firstDayOfThisWeek,
					lastDayOfThisWeek,
					null,
					"[]"
				)
			) {
				timeInThisWeek = timeInThisWeek + 1;
				happyInThisWeekList.push(happy);
				negativeInThisWeekList.push(negative);
				continue;
			}
			if (
				timestamp.isBetween(
					firstDayOfLastWeek,
					lastDayOfLastWeek,
					null,
					"[]"
				)
			) {
				timeInLastWeek = timeInThisWeek + 1;
				happyInLastWeekList.push(happy);
				negativeInLastWeekList.push(negative);
			}
			if (
				timestamp.isBetween(
					firstDayOfLastMonth,
					lastDayOfLastMonth,
					null,
					"[]"
				)
			) {
				timeInLastMonth = timeInLastMonth + 1;
				happyInLastMonthList.push(happy);
				negativeInLastMonthList.push(negative);
			}
		}

		timeSummary.thisWeek = timeInThisWeek;
		timeSummary.lastWeek = timeInLastWeek;
		timeSummary.lastMonth = timeInLastMonth;

		happySummary.thisWeek = average(happyInThisWeekList);
		happySummary.lastWeek = average(happyInLastWeekList);
		happySummary.lastMonth = average(happyInLastMonthList);

		negativeSummary.thisWeek = average(negativeInThisWeekList);
		negativeSummary.lastWeek = average(negativeInLastWeekList);
		negativeSummary.lastMonth = average(negativeInLastMonthList);
	}

	const finalData = {
		timeSummary,
		happySummary,
		negativeSummary,
	};

	return finalData;
};

const average = function (arr) {
	let sum = 0;

	if (arr.length === 0) {
		return 0.0;
	}

	for (let i = 0; i < arr.length; i++) {
		sum += arr[i];
	}
	return Math.round(((sum * 100) / arr.length) * 10) / 10;
};
