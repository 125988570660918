import { getZoomScheduledMeetings, getZoomMeetings } from "../../api/ZoomMeetings";

import {
	fetchZoomMeetingsRequest,
	fetchZoomMeetingsFailure,
	fetchZoomMeetingsSuccess,
} from "./zoomMeetingsAction";

export const fetchZoomMeetings = (zoomUserId) => {

	let meetings = [];

	return (dispatch) => {
		//Initializing the request
		dispatch(fetchZoomMeetingsRequest());
		//Calling the api
		getZoomMeetings(zoomUserId)
			.then((response) => {
				// response is the zoom meeting report
				// const meetings = response.zoom_meetings_list;
				console.log(response)
				meetings = meetings.concat(response.zoom_meeting_list);

				dispatch(fetchZoomMeetingsSuccess(meetings));
			})
			.catch((error) => {
				// error.message is the error message
				dispatch(fetchZoomMeetingsFailure(error.message));
			});

		getZoomScheduledMeetings(zoomUserId)
			.then(response => {

				console.log(response)
				// meetings = meetings.concat(response.zoom_meeting_list.meetings);
				meetings = meetings.concat(response.zoom_meeting_list);
				dispatch(fetchZoomMeetingsSuccess(meetings));
			})
			.catch((error) => {
				dispatch(fetchZoomMeetingsFailure(error.message));
			});
		
			

	};
};
