export const config = (range_min, range_max) => {
	return {
		scales: {
			xAxes: [
				{
					type: "time",
				},
			],
		},
		pan: {
			enabled: true,
			mode: "x",
			speed: 0.1,
			rangeMin: {
				x: range_min,
			},
			rangeMax: {
				x: range_max,
			},
		},
		zoom: {
			enabled: true,
			mode: "x",
			speed: 0.1,
			rangeMin: {
				x: range_min,
			},
			rangeMax: {
				x: range_max,
			},
		},
	};
};
