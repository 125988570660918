import React, { useState } from "react";
import { AmplifySignOut } from "@aws-amplify/ui-react";
import Logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import routes from "../utils/routes";

const NavigationDrawer = () => {
	//For making the state active when clicked i.e nav-item is highlighted when changed.
	const route = window.location.pathname.split("/")[1];
	let index;
	switch (route) {
		case "":
			index = 0;
			break;
		case "users":
			index = 1;
			break;

		case "zoom":
			index = 2;
			break;

		case "calender":
			index = 3;
			break;

		case "settings":
			index = 4;
			break;

		default:
	}
	const array = [false, false, false, false, false];
	array[index] = true;
	const [active, setActive] = useState(array);

	const isActive = (index) => {
		const updatedArray = [false, false, false, false, false];
		updatedArray[index] = true;
		setActive(updatedArray);
	};

	//Style for logo
	const logStyle = {
		width: "102px",
		height: "67px",
		objectFit: "cover",
		objectPosition: "50% 50%",
	};

	return (
		<nav className='col-md-2 min-vh-100 px-0 sidenav-bg'>
			<img
				className='my-4 mx-auto d-block'
				src={Logo}
				alt="I'm beside you"
				style={logStyle}
			/>
			<ul className='nav nav-tab flex-column'>
				{/* {FIXME: Rafactor this} */}
				<li className='nav-item'>
					<Link
						className={`nav-link ${active[0] ? "active" : ""}`}
						to={routes.home}
						onClick={() => isActive(0)}>
						Dashboard
					</Link>
				</li>
				<li className='nav-item'>
					<Link
						className={`nav-link ${active[1] ? "active" : ""}`}
						to={routes.users}
						onClick={() => isActive(1)}>
						User
					</Link>
				</li>
				<li className='nav-item'>
					<Link
						className={`nav-link ${active[2] ? "active" : ""}`}
						to={routes.zoomUsers}
						onClick={() => isActive(2)}>
						Zoom
					</Link>
				</li>
				<li className='nav-item'>
					<Link
						className={`nav-link ${active[3] ? "active" : ""}`}
						to={routes.calender}
						onClick={() => isActive(3)}>
						Calnder
					</Link>
				</li>
				<li className='nav-item'>
					<Link
						className={`nav-link ${active[4] ? "active" : ""}`}
						to={routes.settings}
						onClick={() => isActive(4)}>
						Settings
					</Link>
				</li>
				{/*Sign out Button from AWS Amplify*/}
				<AmplifySignOut />
			</ul>
		</nav>
	);
};

export default NavigationDrawer;
