import React, { useState, useEffect }from "react";
import EngagementGauge from "./EngagementGauge";
import UserEngagementHeatMap from "./UserEngagementHeatMap";

const UserEngagements = (props) => {

	const [userId, setUserId] = useState("all");

	const faceEmotions = props.dataset;

	const style = {
		fontWeight: "bold",
		color: "#6d5e57",
	};

	return (
		<div className='card text-center'>
			<div className='pt-3' style={style}>
				User Engagement
			</div>
			<div className='card-body'>
				<div className='row'>
					<div className='col-md-6'>
						<UserEngagementHeatMap dataset={faceEmotions} setUserId={setUserId}/>
					</div>
					<div className='col-md-6'>
						<EngagementGauge dataset={faceEmotions}  userId={userId}/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserEngagements;
