const plotData = (faceEmotions, userId) => {
	let neutralScore = 0;
	let happyScore = 0;
	let sadScore = 0;
	let angryScore = 0;
	let fearScore = 0;
	let disgustScore = 0;
	let surpriseScore = 0;

	let users = new Set();
	let users_emotion = {};
	if ("emotions" in faceEmotions && faceEmotions.emotions.length != 0) {

		if (userId == "all") {
			for (let i = 0; i < faceEmotions.emotions.length; i++) {
				neutralScore += faceEmotions.emotions[i].neutral;
				happyScore += faceEmotions.emotions[i].happy;
				sadScore += faceEmotions.emotions[i].sad;
				angryScore += faceEmotions.emotions[i].angry;
				fearScore += faceEmotions.emotions[i].fear;
				disgustScore += faceEmotions.emotions[i].disgust;
				surpriseScore += faceEmotions.emotions[i].surprise;
			}
		} else {
			for (let i = 0; i < faceEmotions.emotions.length; i++) {
				if (faceEmotions.emotions[i].user_id == userId) {
					neutralScore += faceEmotions.emotions[i].neutral;
					happyScore += faceEmotions.emotions[i].happy;
					sadScore += faceEmotions.emotions[i].sad;
					angryScore += faceEmotions.emotions[i].angry;
					fearScore += faceEmotions.emotions[i].fear;
					disgustScore += faceEmotions.emotions[i].disgust;
					surpriseScore += faceEmotions.emotions[i].surprise;
				}
			}
		}
	}

	const dataSource = {
		chart: {
			caption: "Emotional Overview",
			subcaption: "Overall",
			showplotborder: "1",
			showLabels: "1",
			animation: "1",
			hoverfillcolor: "#efe3d8",
			numberprefix: "$",
			plottooltext: "<b>$label</b>  <b>$value</b>",
			theme: "fusion",
		},
		category: [
			{
				label: "Emotion",
				tooltext: "Please hover over a sub-category to see details",
				color: "#ffffff",
				value: (
					happyScore +
					sadScore +
					fearScore +
					angryScore +
					disgustScore +
					neutralScore
				).toString(),
				category: [
					{
						label: "Positive",
						color: "#ff884d",
						value: happyScore.toString(),
						category: [
							{
								label: "Happy",
								color: "#f48f57",
								value: happyScore.toString(),
							},
						],
					},

					{
						label: "Negative",
						color: "#5375d4",
						value: (
							sadScore +
							fearScore +
							angryScore +
							disgustScore
						).toString(),
						category: [
							{
								label: "Disgust",
								color: "#934e9f",
								value: disgustScore.toString(),
							},
							{
								label: "Angry",
								color: "#45379b",
								value: angryScore.toString(),
							},
							{
								label: "Sad",
								color: "#1d5fbf",
								value: sadScore.toString(),
							},
							{
								label: "Fear",
								color: "#2097b7",
								value: fearScore.toString(),
							},
						],
					},
					{
						label: "Neutral",
						color: "#ffcd89",
						value: neutralScore.toString(),
						category: [
							{
								label: "Neutral",
								color: "#ffcd89",
								value: neutralScore.toString(),
							},
						],
					},
				],
			},
		],
	};

	return dataSource;
};

export default plotData;
