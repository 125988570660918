import React, { useEffect, useState } from "react";
import Heading from "../../components/Heading";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"

import { useDispatch, useSelector } from "react-redux";
import { fetchZoomMeetings } from "../../store/zoomMeetings/zoomMeetingsEffect";

import ZoomBookingDetail from "../../components/ZoomBookingDetail";
import ZoomBooking from "../../components/ZoomBooking";

import { getZoomUsersWithOffset } from "../../api/ZoomUsers";

import Select from 'react-select';

import "./Calender.css";

import Modal from "react-modal";
Modal.setAppElement("#root");

const eventData = (zoomMeetings) => {

	console.log(zoomMeetings)

	return zoomMeetings.map(zoomMeeting => ({ id: zoomMeeting.uuid, title: zoomMeeting.topic, start: zoomMeeting.start_time, end: zoomMeeting.end_time }));
};

const Calender = () => {
	const dispatch = useDispatch();
	const [modalIsOpen, setIsOpen] = useState(0);
	const [slot, setSlot] = useState({
		start: new Date(),
		end: new Date(),
		duration: 0,
	});

	const [zoomUserId, setZoomUserId] = useState("all");

	useEffect(() => {
		getZoomUsersWithOffset(1, 100000)
			.then((zoomUsers) => {
				console.log(zoomUsers)
				setZoomUserOptions(zoomUsers.zoom_users.users.map(user => ({ value: user.id, label: user.first_name + user.last_name })));
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	// Fetching zoomMeetings
	useEffect(() => {
		dispatch(fetchZoomMeetings(zoomUserId));
	}, [zoomUserId]);

	//Selecting zoom Meetings from the store
	const zoomMeetings = useSelector(
		(state) => state.zoomMeetingsReducer.zoomMeetings
	);

	const [zoomUserOptions, setZoomUserOptions] = useState([]);

	const thisMonth = () => {
		const today = new Date();
		return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(
			2,
			"0"
		)}`;
	};

	const events = eventData(zoomMeetings);
	// console.log(zoomMeetings);

	const handleDateClick = (arg) => { // bind with an arrow function
		console.log(arg)
		setSlot({
			start: arg.start,
			end: arg.end,
			duration: (arg.end - arg.start) / 60 / 1000,
		})
	}

	return (
		<div className='col-md-10 p-4'>
			<div className='px-5'>
				<Heading heading='h4'>Calender</Heading>
				<Select
					className="col-sm-4 offset-md-4 mb-5"
					options={zoomUserOptions}
					isSearchable
					onChange={(value) => {
						setZoomUserId(value.value);
					}}
				/>
				<div className='container w-75'>
					<ZoomBooking slot={slot} modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
					<FullCalendar
						initialView="timeGridWeek"
						selectable={true}
						headerToolbar={{
							start: "prev,next today", // will normally be on the left. if RTL, will be on the right
							center: "title",
							end: "dayGridMonth,timeGridWeek,timeGridDay", // will normally be on the right. if RTL, will be on the left
						}}
						plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
						events={events}
						// dateClick={e => setIsOpen(true)}
						select={e => {
							handleDateClick(e);
							setIsOpen(true);
						}}
						eventClick={e => {
							console.log(e)
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default Calender;
