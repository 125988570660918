import { Auth, API } from "aws-amplify";

export async function getZoomMeetings(zoomUserId) {

    const userId = (await Auth.currentUserInfo()).attributes.sub;

    const apiName = 'ZoomMeetingsAPI';
    const path = '?zoomUserId=' + zoomUserId + '&userId=' + userId;
    const myInit = {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
    };

    return await API.get(apiName, path, myInit)

};

export async function getZoomScheduledMeetings(zoomUserId) {

    const userId = (await Auth.currentUserInfo()).attributes.sub;

    const apiName = 'ZoomScheduledMeetingsAPI';
    const path = '?zoomUserId=' + zoomUserId + '&userId=' + userId;
    const myInit = {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
    };

    return await API.get(apiName, path, myInit)

};