import React from "react";

import FaceEmotions from "./FaceEmotions";
import ValenceScore from "./ValenceScore";
import UserEmotions from "./UserEmotions";

const ChartSection = ({ faceEmotions, userId }) => {
	return (
		<section className='chartSection'>
			<div className='container'>
				<div className='row mt-5'>
					{/* <div className='mb-5'>
						<UserEmotions dataset={faceEmotions} />
					</div> */}
					<div className='mb-5'>
						<FaceEmotions dataset={faceEmotions} userId={userId} />
					</div>
					<div className='mb-5'>
						<ValenceScore dataset={faceEmotions} userId={userId} />
					</div>
				</div>
			</div>
		</section>
	);
};

export default ChartSection;
