const plotFaceEmotion = (faceEmotions, userId) => {
	let emotionDataset = {
		label: "emotion",
		borderColor: "#da6160",
		pointBackgroundColor: "#da6160",
		backgroundColor: "transparent",
		showLines: "false",
		borderWidth: "2",
		lineTension: "0",
		data: [],
	};

	let neutralDataset = {
		label: "neutral",
		borderColor: "#ffcd89",
		pointBackgroundColor: "#ffcd89",
		backgroundColor: "transparent",
		showLines: "false",
		borderWidth: "2",
		lineTension: "0",
		data: [],
	};

	let happyDataset = {
		label: "happy",
		borderColor: "#f48f57",
		pointBackgroundColor: "#f48f57",
		backgroundColor: "transparent",
		showLines: "false",
		borderWidth: "2",
		lineTension: "0",
		data: [],
	};

	let sadDataset = {
		label: "sad",
		borderColor: "#1d5fbf",
		pointBackgroundColor: "#1d5fbf",
		backgroundColor: "transparent",
		showLines: "false",
		borderWidth: "2",
		lineTension: "0",
		data: [],
	};

	let angryDataset = {
		label: "angry",
		borderColor: "#45379b",
		pointBackgroundColor: "#45379b",
		backgroundColor: "transparent",
		showLines: "false",
		borderWidth: "2",
		lineTension: "0",
		data: [],
	};

	let fearDataset = {
		label: "fear",
		borderColor: "#2097b7",
		pointBackgroundColor: "#2097b7",
		backgroundColor: "transparent",
		showLines: "false",
		borderWidth: "2",
		lineTension: "0",
		data: [],
	};

	let disgustDataset = {
		label: "disgust",
		borderColor: "#934e9f",
		pointBackgroundColor: "#934e9f",
		backgroundColor: "transparent",
		showLines: "false",
		borderWidth: "2",
		lineTension: "0",
		data: [],
	};

	let surpriseDataset = {
		label: "surprise",
		borderColor: "#e66b9d",
		pointBackgroundColor: "#e66b9d",
		backgroundColor: "transparent",
		showLines: "false",
		borderWidth: "2",
		lineTension: "0",
		data: [],
	};

	let data = {
		datasets: [
			emotionDataset,
			neutralDataset,
			happyDataset,
			sadDataset,
			angryDataset,
			fearDataset,
			disgustDataset,
			surpriseDataset,
		],
	};

	let emotionScoreList = [];
	let neutralScoreList = [];
	let happyScoreList = [];
	let sadScoreList = [];
	let angryScoreList = [];
	let fearScoreList = [];
	let disgustScoreList = [];
	let surpriseScoreList = [];

	let range_max = new Date();
	let range_min = new Date();

	if ("emotions" in faceEmotions && faceEmotions.emotions.length != 0) {
		range_max = new Date(faceEmotions.emotions[0].timestamp);
		range_min = new Date(
			faceEmotions.emotions[faceEmotions.emotions.length - 1].timestamp
		);

		for (let i = 0; i < faceEmotions.emotions.length; i++) {
			const userIdInEmotions = faceEmotions.emotions[i].user_id;

			if (userIdInEmotions != userId) {
				continue;
			}

			let emotionScore = {};
			let neutralScore = {};
			let happyScore = {};
			let sadScore = {};
			let angryScore = {};
			let fearScore = {};
			let disgustScore = {};
			let surpriseScore = {};

			emotionScore.x = faceEmotions.emotions[i].timestamp;
			emotionScore.y = (1 - faceEmotions.emotions[i].neutral) * 100;
			emotionScoreList.push(emotionScore);

			neutralScore.x = faceEmotions.emotions[i].timestamp;
			neutralScore.y = faceEmotions.emotions[i].neutral * 100;
			neutralScoreList.push(neutralScore);

			happyScore.x = faceEmotions.emotions[i].timestamp;
			happyScore.y = faceEmotions.emotions[i].happy * 100;
			happyScoreList.push(happyScore);

			sadScore.x = faceEmotions.emotions[i].timestamp;
			sadScore.y = faceEmotions.emotions[i].sad * 100;
			sadScoreList.push(sadScore);

			angryScore.x = faceEmotions.emotions[i].timestamp;
			angryScore.y = faceEmotions.emotions[i].angry * 100;
			angryScoreList.push(angryScore);

			fearScore.x = faceEmotions.emotions[i].timestamp;
			fearScore.y = faceEmotions.emotions[i].fear * 100;
			fearScoreList.push(fearScore);

			disgustScore.x = faceEmotions.emotions[i].timestamp;
			disgustScore.y = faceEmotions.emotions[i].disgust * 100;
			disgustScoreList.push(disgustScore);

			surpriseScore.x = faceEmotions.emotions[i].timestamp;
			surpriseScore.y = faceEmotions.emotions[i].surprise * 100;
			surpriseScoreList.push(surpriseScore);
		}

		emotionDataset.data = emotionScoreList;
		neutralDataset.data = neutralScoreList;
		happyDataset.data = happyScoreList;
		sadDataset.data = sadScoreList;
		angryDataset.data = angryScoreList;
		fearDataset.data = fearScoreList;
		disgustDataset.data = disgustScoreList;
		surpriseDataset.data = surpriseScoreList;
	}

	data = {
		datasets: [
			emotionDataset,
			neutralDataset,
			happyDataset,
			sadDataset,
			angryDataset,
			fearDataset,
			disgustDataset,
			surpriseDataset,
		],
	};

	const finalData = {
		data,
		range_max,
		range_min,
	};

	return finalData;
};

export default plotFaceEmotion;
