import {
	getFaceEmotions,
	getFaceEmotionsInBetween,
} from "../../api/FaceEmotions";

import {
	fetchFaceEmotionsRequest,
	fetchFaceEmotionsSuccess,
	fetchFaceEmotionsFailure,
} from "./faceEmotionsAction";

export const fetchFaceEmotions = () => {
	return (dispatch) => {
		//Initializing Request
		dispatch(fetchFaceEmotionsRequest());
		//Calling the api
		getFaceEmotions()
			.then((response) => {
				// response.data is the emotions
				dispatch(fetchFaceEmotionsSuccess(response));
			})
			.catch((error) => {
				// error.message is the error message
				dispatch(fetchFaceEmotionsFailure(error.message));
			});
	};
};

export const fetchFaceEmotionsInBetween = (from, to) => {
	return (dispatch) => {
		//Initializing Request
		dispatch(fetchFaceEmotionsRequest());
		//Calling the api
		getFaceEmotionsInBetween(from, to)
			.then((response) => {
				// response is the emotions
				dispatch(fetchFaceEmotionsSuccess(response));
			})
			.catch((error) => {
				// error.message is the error message
				dispatch(fetchFaceEmotionsFailure(error.message));
			});
	};
};
