const plotData = (faceEmotions) => {
	let dateFormat = require("dateformat");
	let valenceScoreList = [];
	let timestampsList = [];

	if ("emotions" in faceEmotions && faceEmotions.emotions.length != 0) {
		//console.log(faceEmotions.emotions);
		//console.log("aaaa");

		for (let i = 0; i < faceEmotions.emotions.length; i++) {
			let valenceScore = {};
			let timestamp = {};

			timestamp.label = dateFormat(
				new Date(faceEmotions.emotions[i].timestamp),
				"h:MM:ss TT"
			);
			timestampsList.push(timestamp);
			valenceScore.value =
				faceEmotions.emotions[i].happy -
				(faceEmotions.emotions[i].angry +
					faceEmotions.emotions[i].fear +
					faceEmotions.emotions[i].disgust +
					faceEmotions.emotions[i].sad);
			valenceScoreList.push(valenceScore);
		}
	}

	const dataSource = {
		chart: {
			// caption: "Summer Olympics Medal Tally",
			subcaption: "Users Valence Score",
			yaxisname: "Score",
			numvisibleplot: "70",
			labeldisplay: "auto",
			theme: "fusion",
		},
		categories: [
			{
				category: timestampsList,
			},
		],
		dataset: [
			{
				seriesname: "Valence Score",
				data: valenceScoreList,
			},
		],
	};

	return dataSource;
};

export default plotData;
