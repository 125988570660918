import React, { useState, useEffect }from "react";
import EmotionDonut from "./EmotionDonut";
import UserHeatMap from "./UserHeatMap";

const UserEmotions = (props) => {

	const [userId, setUserId] = useState("all");

	const faceEmotions = props.dataset;
	const users = props.users;

	const style = {
		fontWeight: "bold",
		color: "#6d5e57",
	};

	return (
		<div className='card text-center'>
			<div className='pt-3' style={style}>
				User Emotions
			</div>
			<div className='card-body'>
				<div className='row'>
					<div className='col-md-6'>
						<UserHeatMap dataset={faceEmotions} users={users} setUserId={setUserId}/>
					</div>
					<div className='col-md-6'>
						<EmotionDonut dataset={faceEmotions} userId={userId}/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserEmotions;
