import React, { useEffect, useState } from "react";
// import moment from "moment";
import moment from 'moment-timezone';
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchZoomMeetings } from "../store/zoomMeetings/zoomMeetingsEffect";
import { fetchZoomMeetingReport } from "../store/zoomMeetingReport/zoomMeetingReportEffect";

import ZoomBooking from "../components/ZoomBooking";

import Heading from "../components/Heading";
import Card from "../components/Card";
import Table from "../components/Table";

import { ZOOM_RECORDING } from "../utils/schema";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";

import routes from "../utils/routes";

const iconStyle = {
	fontSize: 25,
	color: "grey",
};

const ZoomMeetings = () => {
	const dispatch = useDispatch();
	const { zoomUserId } = useParams();

	const [modalIsOpen, setIsOpen] = useState(0);

	// Fetching zoomMeetings
	useEffect(() => {
		dispatch(fetchZoomMeetings(zoomUserId));
	}, []);

	//Selecting zoom Meetings from the store
	const zoomMeetings = useSelector(
		(state) => state.zoomMeetingsReducer.zoomMeetings
	);

	const reversedMeetings = zoomMeetings.sort(function (a, b) {
		if (a.start_time > b.start_time) {
			return -1;
		} else {
			return 1;
		}
	});

	console.log(zoomMeetings)

	// Creating zoomMeeting List
	const zoomMeetingList = reversedMeetings
		? reversedMeetings.map((zoomMeeting) => {
			// const from = moment(zoomMeeting.start_time, moment.ISO_8601)
			// 	.utc()
			// 	.format();
			// const to = moment(from, moment.ISO_8601)
			// 	.add(zoomMeeting.duration, "minute")
			// 	.utc()
			// 	.format();

			const timezone = moment.tz.guess();
			const from = moment(zoomMeeting.start_time, moment.ISO_8601).tz(timezone).format("YYYY-MM-DD[T]HH:mm:ss");
			const to = moment(from, moment.ISO_8601).add(zoomMeeting.duration, 'minute').tz(timezone).format("YYYY-MM-DD[T]HH:mm:s");
			
			return (
				<tr key={zoomMeeting.uuid}>
					<td>{zoomMeeting.uuid}</td>
					<td>{zoomMeeting.topic}</td>
					<td>{zoomMeeting.start_time}</td>
					<td>
						{zoomMeeting.duration}
						<span className='h6'>min</span>
					</td>
					<td className="td-actions">
						{zoomMeeting.join_url ?
							<a href={zoomMeeting.join_url} target="_blank">
								{zoomMeeting.join_url}
							</a>
							: <div>Can not join</div>}
					</td>
					<td
						className='td-actions'
						onClick={() => {
							dispatch(
								fetchZoomMeetingReport(zoomMeeting.uuid)
							);
						}}>
						<Link
							to={
								routes.zoomMeetingDashboard + 
								"?zoomMeetingId=" +
								encodeURIComponent(
									encodeURIComponent(zoomMeeting.uuid)
								)
								+ "&from=" + from + "&to=" + to
							}>
							<FontAwesomeIcon
								style={iconStyle}
								icon={faChartLine}
							/>
						</Link>
					</td>
				</tr>
			);
		})
		: "";

	return (
		<div className='col-md-10 p-4 mt-5'>

			<Heading heading='h4'>Zoom Meeting List</Heading>

			<ZoomBooking modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}/>

			<div className="mb-4 text-center">
				{/* <Link className='btn btn-primary'
					to={
						"/zoom/users/" + zoomUserId + "/meetings/create"
					}>
					Creat new meeting
				</Link> */}

				<button
							type="button"
							className='btn btn-primary'
							onClick={() => setIsOpen(true)}
						>
							Creat new meeting
						</button>
			</div>
			<Card>
				<Table schema={ZOOM_RECORDING} data={zoomMeetingList} />
			</Card>
		</div>
	);
};

export default ZoomMeetings;
