import moment from 'moment-timezone';

const plotData = (faceEmotions, userId) => {
	let users = new Set();
	let engagementRatio = 0;
	let usersEngagement = {};

	const params = new URLSearchParams(window.location.search);
	const from = params.get("from");
	const to = params.get("to");
	const duration = moment(to.split('T').join(' ')).diff(moment(from.split('T').join(' ')), 'minutes');

	if ("emotions" in faceEmotions && faceEmotions.emotions.length != 0) {

		for (let i = 0; i < faceEmotions.emotions.length; i++) {
			users.add(faceEmotions.emotions[i].user_id);
		}

		users.forEach((a) => {
			usersEngagement[a] = new Set();
		});

		if (userId == "all") {
			for (let i = 0; i < faceEmotions.emotions.length; i++) {
				const hhmm = faceEmotions.emotions[i].timestamp.split(' ')[1].substr(0, 4);
				usersEngagement[faceEmotions.emotions[i].user_id].add(hhmm)
			}

			let faceCounts = 0;

			users.forEach((a) => {
				faceCounts += usersEngagement[a].size;
			});

			engagementRatio = faceCounts * 100 / (duration * users.size / 10);

		} else {
			for (let i = 0; i < faceEmotions.emotions.length; i++) {
				if (faceEmotions.emotions[i].user_id == userId) {
					const hhmm = faceEmotions.emotions[i].timestamp.split(' ')[1].substr(0, 4);
					usersEngagement[faceEmotions.emotions[i].user_id].add(hhmm)
				}
			}

			const faceCounts = usersEngagement[userId].size;
			engagementRatio = faceCounts * 100 / (duration / 10);
		}

		const dataSource = {
			chart: {
				caption: "Engagement Overview",
				subcaption: "over all",
				numbersuffix: "%",
				gaugefillmix: "{dark-20},{light+70},{dark-10}",
				theme: "fusion"
			},
			colorrange: {
				color: [
					{
						minvalue: "0",
						maxvalue: "25",
						label: "low",
						code: "#F2726F"
					},
					{
						minvalue: "25",
						maxvalue: "50",
						label: "middle",
						code: "#FFC533"
					},
					{
						minvalue: "50",
						maxvalue: "100",
						label: "High",
						code: "#62B58F"
					}
				]
			},
			pointers: {
				pointer: [
					{
						value: engagementRatio
					}
				]
			}
		};

		return dataSource;
	};
}
export default plotData;
