import React from "react";

const Table = ({ schema, data }) => {
	const style = {
		borderBottom: "2px solid #dee2e6",
		borderTop: "1px solid #dee2e6",
		padding: "0.75rem",
	};

	const tableHeading = schema.map((fields) => {
		return (
			<th key={fields.id} style={style}>
				{fields.content}
			</th>
		);
	});

	return (
		<table className='table text-left'>
			<thead>
				<tr>{tableHeading}</tr>
			</thead>
			<tbody>{data}</tbody>
		</table>
	);
};

export default Table;
