export const fetchZoomUsersRequest = () => {
	return {
		type: "FETCH_ZOOM_USERS_REQUEST",
	};
};

export const fetchZoomUsersSuccess = (zoomUsers) => {
	return {
		type: "FETCH_ZOOM_USERS_SUCCESS",
		payload: zoomUsers,
	};
};

export const fetchZoomUsersFailure = (error) => {
	return {
		type: "FETCH_ZOOM_USERS_FAILURE",
		payload: error,
	};
};
