import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchFaceEmotions } from "../../store/faceEmotions/faceEmotionsEffect";

import Heading from "../../components/Heading";
import Info from "../../components/Info";
import SummaryCardSection from "../../components/Summary";
import ChartSection from "../../components/ChartsPerUSer";

const DashboardPerUser = () => {
	//Fetching faceEmotions
	const dispatch = useDispatch();
	const faceEmotions = useSelector(
		(state) => state.faceEmotionsReducer.faceEmotions
	);

	useEffect(() => {
		dispatch(fetchFaceEmotions());
	}, []);

	//const user = useSelector((state) => state.zoomUserReducer.zoomUser);

	const { userId } = useParams();
	const userName = decodeURI(window.location.search.substring(6));

	console.log(faceEmotions)
	return (
		<div className='col-md-10 p-4'>
			<Heading heading='h5'>
				IBY analyzes online communication every seconds.
			</Heading>
			<Info userId={userId} userName={userName} />
			<SummaryCardSection faceEmotions={faceEmotions} userId={userId} />
			<ChartSection faceEmotions={faceEmotions} userId={userId} />
		</div>
	);
};

export default DashboardPerUser;
