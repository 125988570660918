import React from "react";

import FaceEmotions from "./FaceEmotionsV2"
import UserEmotions from "./UserEmotions";
import ValenceScore from "./ValenceScore";

const ChartSection = ({ faceEmotions, users }) => {
	return (
		<section className='chartSection'>
			<div className='container'>
				<div className='row mt-5'>
					<div className='mb-5'>
						<UserEmotions dataset={faceEmotions} users={users}/>
					</div>
					<div className='mb-5'>
						<FaceEmotions dataset={faceEmotions} />
					</div>
					<div className='mb-5'>
						<ValenceScore dataset={faceEmotions} />
					</div>
				</div>
			</div>
		</section>
	);
};

export default ChartSection;
