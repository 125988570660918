import { Auth, API } from "aws-amplify";

export async function getUsers() {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "UsersAPI";
	const path = "?account_id=" + accountId;
	const myInit = {
		headers: {
			Authorization: `Bearer ${(await Auth.currentSession())
				.getIdToken()
				.getJwtToken()}`,
		},
	};

	return await API.get(apiName, path, myInit);
}

export async function getRegisteredUsers() {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "RegisteredUsersAPI";
	const path = "?account_id=" + accountId;
	const myInit = {
		headers: {
			Authorization: `Bearer ${(await Auth.currentSession())
				.getIdToken()
				.getJwtToken()}`,
		},
	};

	return await API.get(apiName, path, myInit);
}

export async function getUsersWithOffset(offset, perPage) {
	const accountId = (await Auth.currentUserInfo()).attributes.sub;
	const apiName = "UsersAPI";
	const path =
		"?account_id=" +
		accountId +
		"&offset=" +
		offset +
		"&perPage=" +
		perPage;
	const myInit = {
		headers: {
			Authorization: `Bearer ${(await Auth.currentSession())
				.getIdToken()
				.getJwtToken()}`,
		},
	};

	return await API.get(apiName, path, myInit);
}
