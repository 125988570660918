import FusionCharts from "fusioncharts";

const plotData = (faceEmotions) => {

	let dateFormat = require("dateformat");
	let data_set = [];
	// const { faceEmotions } = props;
	let start_date = new Date();
	let end_date = new Date();
	end_date.setDate(end_date.getDate());
	start_date.setDate(start_date.getDate() - 5);
	start_date = dateFormat(start_date, "m/d/yyyy H");
	end_date = dateFormat(end_date, "m/d/yyyy H");

	if ("emotions" in faceEmotions && faceEmotions.emotions.length != 0) {
		for (let i = 0; i < faceEmotions.emotions.length; i++) {
			let data = [];
			data.push(dateFormat(new Date(faceEmotions.emotions[i].timestamp), "H:M:ss"));// H:M:ss form seconds
			data.push((1 - faceEmotions.emotions[i].neutral) * 100);
			data.push(faceEmotions.emotions[i].neutral * 100);
			data.push(faceEmotions.emotions[i].happy * 100);
			data.push(faceEmotions.emotions[i].sad * 100);
			data.push(faceEmotions.emotions[i].angry * 100);
			data.push(faceEmotions.emotions[i].fear * 100);
			data.push(faceEmotions.emotions[i].disgust * 100);
			data.push(faceEmotions.emotions[i].surprise * 100);
			data_set.push(data);
		};
	}

	const dataFetch = data_set;
	const schemaFetch = [{
		"name": "Time",
		"type": "date",
		"format": "%H:%M:%S" // %H:%M:%S for seconds 
	}, {
		"name": "Emotion",
		"type": "number"
	}, {
		"name": "Neutral",
		"type": "number"
	}, {
		"name": "Happy",
		"type": "number"
	}, {
		"name": "Sad",
		"type": "number"
	}, {
		"name": "Angry",
		"type": "number"
	}, {
		"name": "Fear",
		"type": "number"
	}, {
		"name": "Disgust",
		"type": "number"
	}, {
		"name": "Surprise",
		"type": "number"
	}
	];
	const fusionTable = new FusionCharts.DataStore().createDataTable(
		dataFetch,
		schemaFetch,
	);
	const dataSource = {
		data: fusionTable,
		chart: { "palettecolors": "dd6f6e,ffcf8e,f48f57,1d5fbf,45379b,2097b7,934e9f,e66b9d" },
		caption: {

		},
		subcaption: {

		},
		legend: {
			position: 'top',
			alignment: 'middle'
		},
		yaxis: [
			{
				plot: [
					{
						value: "Emotion",
						type: "column",
						connectnulldata: true,

					},
					{
						value: "Neutral",
						type: "line",
						connectnulldata: true,

					},
					{
						value: "Happy",
						type: "line",
						connectnulldata: true,
					},
					{
						value: "Sad",
						type: "line",
						connectnulldata: true,
					},
					{
						value: "Angry",
						type: "line",
						connectnulldata: true,
					},
					{
						value: "Fear",
						type: "line",
						connectnulldata: true,
					},
					{
						value: "Disgust",
						type: "line",
						connectnulldata: true,
					}
					,
					{
						value: "Surprise",
						type: "line",
						connectnulldata: true,

					}
				]
			}
		],
		xaxis: {
			// showclippingcue: "1",
			"autoClipNullData": "Hour",
			// "autoClipMultiplier": 4,
			initialinterval: {
				from: start_date,
				to: end_date
			}
		},
	};

	return dataSource;
};

export default plotData;
