const plotValenceScore = (faceEmotions) => {
	let valenceDataset = {
		label: "valence",
		borderColor: "#da6160",
		pointBackgroundColor: "#da6160",
		backgroundColor: "transparent",
		showLines: "false",
		borderWidth: "2",
		lineTension: "0",
		data: [],
	};

	let data = { datasets: [valenceDataset] };

	let valenceScoreList = [];

	let range_max = new Date();
	let range_min = new Date();

	if ("emotions" in faceEmotions && faceEmotions.emotions.length != 0) {
		range_max = new Date(faceEmotions.emotions[0].timestamp);
		range_min = new Date(
			faceEmotions.emotions[faceEmotions.emotions.length - 1].timestamp
		);

		for (let i = 0; i < faceEmotions.emotions.length; i++) {
			const userIdInEmotions = faceEmotions.emotions[i].user_id;

			let valenceScore = {};

			valenceScore.x = faceEmotions.emotions[i].timestamp;
			valenceScore.y =
				faceEmotions.emotions[i].happy -
				(faceEmotions.emotions[i].angry +
					faceEmotions.emotions[i].fear +
					faceEmotions.emotions[i].disgust +
					faceEmotions.emotions[i].sad);
			valenceScoreList.push(valenceScore);
		}

		valenceDataset.data = valenceScoreList;

		data = { datasets: [valenceDataset] };
	}

	const finalData = {
		data,
		range_max,
		range_min,
	};

	return finalData;
};

export default plotValenceScore;
