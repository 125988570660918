export const fetchZoomUserRequest = () => {
	return {
		type: "FETCH_ZOOM_USER_REQUEST",
	};
};

export const fetchZoomUserSuccess = (zoomUser) => {
	return {
		type: "FETCH_ZOOM_USER_SUCCESS",
		payload: zoomUser,
	};
};

export const fetchZoomUserFailure = (error) => {
	return {
		type: "FETCH_ZOOM_USER_FAILURE",
		payload: error,
	};
};


