import React from "react";
import plotData from "./helper";

import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, FusionTheme, TimeSeries);
charts(FusionCharts);

const EmotionBarLine = (props) => {
	console.log(props.dataset)
	const faceEmotions = props.dataset;
	const dataSource = plotData(faceEmotions);

	const style = {
		fontWeight: "bold",
		color: "#6d5e57",
	};

	return (
		<div className='card text-center'>
			<div className='pt-3' style={style}>
				Facial Emotion Score
			</div>
			<div className='card-body'>
				<ReactFC
					type='timeseries'
					width='100%'
					height='600'
					dataFormat='JSON'
					renderAt= "container"
					dataSource={dataSource}
				/>
			</div>
		</div>
	);
};

export default EmotionBarLine;
