import React from "react";

const Info = (props) => {
	const style = {
		backgroundColor: "#e2c9b5",
		marginRight: "20px",
		borderRadius: "15px",
	};

	const userInfo = props.userId ? (
		<section className='m-4 mb-5'>
			<div className='d-flex'>
				<div className='d-flex flex-column py-2 px-4' style={style}>
					<span className='small'>Name</span>
					<span className='h5'>
						{props.userName ? props.userName : "-"}
					</span>
				</div>

				<div className='d-flex flex-column py-2 px-4' style={style}>
					<span className='small'>ID</span>
					<span className='h5'>{props.userId}</span>
				</div>
			</div>
		</section>
	) : null;

	if (props.zoomMeeting && props.zoomMeeting.meetingDate) {
		var meetingDate = props.zoomMeeting.start_time.toString().split("T")[0];
		var topic = props.zoomMeeting.topic;
		var duration = props.zoomMeeting.duration;
		var participantsCount = props.zoomMeeting.participants_count;
	}

	const zoomInfo = props.zoomMeeting ? (
		<section className='m-4 mb-5'>
			<div className='d-flex'>
				<div className='d-flex flex-column py-2 px-4' style={style}>
					<span className='small'>Meeting Date</span>
					<span className='h5'>{meetingDate}</span>
				</div>

				<div className='d-flex flex-column py-2 px-4' style={style}>
					<span className='small'>Topic</span>
					<span className='h5'>{topic}</span>
				</div>
				<div className='d-flex flex-column py-2 px-4' style={style}>
					<span className='small'>Duration</span>
					<span className='h5'>{duration}</span>
				</div>
				<div className='d-flex flex-column py-2 px-4' style={style}>
					<span className='small'>Participants Count</span>
					<span className='h5'>{participantsCount}</span>
				</div>
			</div>
		</section>
	) : null;

	const info = props.userId ? userInfo : zoomInfo;

	return info;
};

export default Info;
